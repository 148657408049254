import axios from "axios"

export const sendRequest = options => {
  const mock = false

  if (!mock) {
    return axios({
      method: "get",
      // url: "https://cors-anywhere.herokuapp.com/" + options.url,
      url: options.url,
      responseType: options.responseType || "json",
    })
  }

  return new Promise((resolve, reject) => {
    const randomTime = Math.random() * 1000 + 700
    setTimeout(() => {
      if (options.url.includes("WorkObjects")) {
        resolve({
          data: [
            {
              objectId: 1,
              objectAddress: {
                addressId: 1,
                city: "Warszawa",
                address: "ul. Adasia Dyminskiego d.31245",
              },
              fileId: [1, 2],
              phoneNumber: "789552141",
              openHours: "9.15 - 21.30",
            },
          ],
        })
      }

      if (options.url.includes("File")) {
        resolve({ data: `https://fakeimg.pl/${options.url.split("=")[1]}/` })
      }

      if (options.url.includes("Products")) {
        resolve({
          data: [
            {
              price: 50,
              description: "Strzyżenie",
              defaultTime: {
                ticks: 18000000000,
                days: 0,
                hours: 0,
                milliseconds: 0,
                minutes: 30,
                seconds: 0,
                totalDays: 0.020833333333333332,
                totalHours: 0.5,
                totalMilliseconds: 1800000,
                totalMinutes: 30,
                totalSeconds: 1800,
              },
              fileIds: [1, 2],
            },
          ],
        })
      }

      if (options.url.includes("Workers")) {
        resolve({
          data: [
            {
              workerId: 1,
              name: "Asia",
              surname: "Busia",
              phoneNumber: "123456789",
              email: "asia.busia@mailinator.com",
              products: [
                {
                  price: 50,
                  description: "Strzyżenie",
                  defaultTime: {
                    ticks: 18000000000,
                    days: 0,
                    hours: 0,
                    milliseconds: 0,
                    minutes: 30,
                    seconds: 0,
                    totalDays: 0.020833333333333332,
                    totalHours: 0.5,
                    totalMilliseconds: 1800000,
                    totalMinutes: 30,
                    totalSeconds: 1800,
                  },
                  fileIds: [1, 2],
                },
              ],
              workObjects: [
                {
                  objectId: 1,
                  objectAddress: {
                    addressId: 1,
                    city: "Warszawa",
                    address: "ul. Adasia Dyminskiego d.31245",
                  },
                  fileId: [1, 2],
                  phoneNumber: "789552141",
                  openHours: "9.15 - 21.30",
                },
              ],
            },
          ],
        })
      }

      if (options.url.includes("Intervals")) {
        resolve({
          data: [
            {
              defaultProductInterval: {
                ticks: 27000000000,
                days: 0,
                hours: 0,
                milliseconds: 0,
                minutes: 45,
                seconds: 0,
                totalDays: 0.03125,
                totalHours: 0.75,
                totalMilliseconds: 2700000,
                totalMinutes: 45,
                totalSeconds: 2700,
              },
              defaultBreak: {
                ticks: 36000000000,
                days: 0,
                hours: 1,
                milliseconds: 0,
                minutes: 0,
                seconds: 0,
                totalDays: 0.041666666666666664,
                totalHours: 1,
                totalMilliseconds: 3600000,
                totalMinutes: 60,
                totalSeconds: 3600,
              },
            },
          ],
        })
      }

      if (options.url.includes("Payments")) {
        resolve({
          data: [
            {
              paymentType: 1,
              cashAdvancePercent: 50,
              resignDeductionPercent: 33,
              bancAccount: "52928810400292624889374684",
            },
          ],
        })
      }

      if (options.url.includes("schedule")) {
        resolve({
          data: [
            {
              date: new Date(2021, 1, 15),
              workers: [
                {
                  name: "Kasia",
                  hours: [],
                },
                {
                  name: "Basia",
                  leave: true,
                  hours: [],
                },
                {
                  name: "Tomek",
                  hours: [
                    {
                      start: new Date(2021, 1, 15, 9, 0, 0),
                      end: new Date(2021, 1, 15, 11, 0, 0),
                    },
                    {
                      start: new Date(2021, 1, 15, 15, 0, 0),
                      end: new Date(2021, 1, 15, 18, 0, 0),
                    },
                  ],
                },
              ],
            },
            {
              date: new Date(2021, 1, 22),
              workers: [
                {
                  name: "Kasia",
                  hours: [
                    {
                      start: new Date(2021, 1, 22, 9, 0, 0),
                      end: new Date(2021, 1, 22, 11, 0, 0),
                    },
                    {
                      start: new Date(2021, 1, 22, 15, 0, 0),
                      end: new Date(2021, 1, 22, 18, 0, 0),
                    },
                  ],
                },
                {
                  name: "Basia",
                  leave: true,
                  hours: [],
                },
                {
                  name: "Tomek",
                  hours: [],
                },
              ],
            },
          ],
        })
      }

      //
    }, randomTime)
  })
}
